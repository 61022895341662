.default > div > div {
  border-radius: 2rem;
  background: white;
}

.default > div > div > div {
  background: white;
}
.default input {
  background: white;
}
