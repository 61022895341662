:root {
  --font-color: rgba(0, 0, 0, 0.54);
  --font-color-hover: rgba(0, 0, 0, 0.87);

  --border-color: rgb(223, 223, 223);
  --bg-color: rgb(255, 255, 255);

  --white: rgb(255, 255, 255);
  --black: rgb(0, 0, 0);
  --black-80: rgb(0, 0, 0, 0.8);
  --black-40: rgb(0, 0, 0, 0.4);
  --black-50: rgba(0, 0, 0, 0.5);
  --black-87: rgba(0, 0, 0, 0.5);
  --black-30: rgb(0, 0, 0, 0.3);
  --black-20: rgb(0, 0, 0, 0.2);
  --black-10: rgb(0, 0, 0, 0.1);
  --black-05: rgb(0, 0, 0, 0.05);
  --black-02: rgb(0, 0, 0, 0.02);

  --red: #f18c8e;
  --orange: #f0b7a4;
  --yellow: #f1d1b5;
  --blue: #568ea6;
  --dark-blue: #305f72;
}

a {
  text-decoration: none;
  color: inherit;
}

h1,
h2,
h3,
h4,
h5,
ul {
  margin: 0;
}
